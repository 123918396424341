import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo-capgemini.svg";
import "./header.scss";

const Header = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const nav = [
        { name: "home", path: "/" },
        { name: "group", path: "/group" },
        // { name: "join", path: "/join" },
        { name: "faq", path: "/faq" }
    ];

    const closeOnClick = () => setOpen(false);

    return (
        <header className={`header ${open ? "header--open" : "header--closed"}`}>
            <Link to="/" className="header__logo">
                <img src={logo} alt={t("header.brand")} className="header__logo__image" />
            </Link>

            <div className="header__toggle">
                <button type="button" className="header__toggle__button" onClick={() => setOpen(!open)}>
                    <span className={`header__toggle__button__part ${open ? "open" : "closed"}`} />
                    <span className={`header__toggle__button__part ${open ? "open" : "closed"}`} />
                    <span className={`header__toggle__button__part ${open ? "open" : "closed"}`} />
                </button>
            </div>

            <nav className={`header__nav ${open ? "header__nav--open" : "header__nav--closed"}`}>
                {nav.map((link) => {
                    const active = location.pathname === link.path ? "header__nav__link--active" : "";
                    return (
                        <Link key={link.name} to={link.path} className={`header__nav__link ${active}`} onClick={closeOnClick}>
                            {t(`header.${link.name}`)}
                        </Link>
                    );
                })}
            </nav>
        </header>
    );
};

export default Header;
