import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../config/authentication/auth-context";
import Header from "./shared/components/header/header";
import Footer from "./shared/components/footer/footer";
import Homepage from "./entities/homepage";
import Candidate from "./entities/candidate";
import Auth from "./entities/auth";
import "../config/i18next/i18next";
import "../config/analytics/analytics.config";
import "../config/notifications/notifications.config";
import "./app.scss";

const App = () => (
    <React.StrictMode>
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Homepage.Home />} />
                <Route path="/group" element={<Homepage.Group />} />
                <Route path="/faq" element={<Homepage.Faq />} />
                <Route path="/login" element={<Auth.Login />} />
                {/* <Route path="/join" element={<Candidate.Join />}/> */}
                {/* <Route path="/join/success" element={<Candidate.JoinSuccess />}/> */}
                <Route
                    path="/logout"
                    element={
                        <AuthProvider>
                            <Auth.Logout />
                        </AuthProvider>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <AuthProvider>
                            <Auth.Admin />
                        </AuthProvider>
                    }
                />
                <Route
                    path="/admin/candidates"
                    element={
                        <AuthProvider>
                            <Candidate.ListCandidates />
                        </AuthProvider>
                    }
                />
            </Routes>
            <Footer />
        </BrowserRouter>
    </React.StrictMode>
);

export default App;
